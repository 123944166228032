
.headd {

    display: flex;
    background-color: #EFC079;
    max-height: 100px;
}

.thaste
{

    text-align: right;
    font-size: 150%;
    padding-left: 10%;
}

.logo {

    margin-left: 0px;

    margin-bottom: auto;
}